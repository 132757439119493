import React from "react";
import { Container, Typography, Box, keyframes } from "@mui/material";
import imgRibbon from "../../img/Services/Remesas/ribbonRemesas.webp";
import ButtonArrow from "../ButtonArrow";
import SliderRemesas from "./SlierRemesas";
import GroupButtons from "../GroupButtons";

//Para hacer las pulsaciones del titulo principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export default function Remesas() {
  return (
    <Container maxWidth="lg" sx={{ marginBottom: { xs: "40%", sm: "40%", md: "10%", lg: "2%" } }}>
      <ButtonArrow />
      <GroupButtons />
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "4.2rem", sm: "6.1rem", md: "6.1rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          REMESAS
        </Typography>
      </Box>

      {/*introduction Start*/}
      <Box sx={{ my: 1 }}>
        <Typography variant="h4" align="center" color={"#0c213d"} gutterBottom>
          ¿Que son las Remesas?
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "justify", mx: "3rem" }}
        >
          Las remesas son transferencias de dinero que las personas envían del
          extranjero a sus familiares, siendo una fuente crucial de apoyo
          económico. En <strong>Caja Solidaria La Huerta</strong>, puedes cobrar
          tu remesa sin ningun costo en cualquiera de nuestras 11 sucursales.
        </Typography>
      </Box>
      {/*introduction End*/}
        <SliderRemesas />
    </Container>
  );
}
