import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import { Grid, Grow, Slide } from "@mui/material";

// Imagenes comfiguradas para el slider

// Diapositiva del 25 aniversario
import anios from "../../img/sliderProducts/Aniversario/Anios.webp";
import aniversario from "../../img/sliderProducts/Aniversario/LetrasVeinte.webp";
import Logoveinticinco from "../../img/sliderProducts/logo.webp";

// Diapositiva del Soli-Auto
import Vehiculos from "../../img/sliderProducts/SoliAuto/VehiculosPlazos.webp";
import Moto from "../../img/sliderProducts/SoliAuto/EstrenaYa.webp";
import Iva from "../../img/sliderProducts/SoliAuto/ivaSoliauto.webp";

// Diapositivas Tarjeta Debito
import Letras from "../../img/sliderProducts/DisenioLetras.webp";
import TarjetaDebito from "../../img/sliderProducts/TarjetaDebito.webp";
import Actor from "../../img/sliderProducts/Actor.webp";

// Dispositiva Cajerps
import Texto from "../../img/sliderProducts/Texto.webp";
import Red from "../../img/sliderProducts/Red.webp";
import Cajeros from "../../img/sliderProducts/Cajeros.webp";

// Para la imagen de app
import Descarga from "../../img/sliderProducts/Aplicacion/descargaLetras.webp";
import CandadinCelLetras from "../../img/sliderProducts/Aplicacion/CandadinCelLetras.webp";
import NuestraApp from "../../img/sliderProducts/Aplicacion/nuestraApp.webp";
import CslhMovil from "../../img/sliderProducts/Aplicacion/cslhmovil.webp";
import CslhAmovil from "../../img/sliderProducts/Aplicacion/cslhamovil.webp";
import GooglePlay from "../../img/sliderProducts/Aplicacion/googlePlay.webp";
import AppStore from "../../img/sliderProducts/Aplicacion/appStore.webp";

//Para la diapositiva de actualiza expediente
import estimadoSocio from "../../img/sliderProducts/ActualizacionExpediente/estimadoSocio.webp";
import puntero from "../../img/sliderProducts/ActualizacionExpediente/puntero.webp";
import meses from "../../img/sliderProducts/ActualizacionExpediente/meses.webp";
import presionaAqui from "../../img/sliderProducts/ActualizacionExpediente/presionaAqui.webp";

import { useNavigate } from "react-router-dom";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow next-arrow"></div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow prev-arrow"></div>
    </div>
  );
};

const MyCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  //para redireccionar
  const handleCarouselClick = () => {
    if (currentSlide === 0) {
      navigate("/actualizacionExpediente");
    } else if (currentSlide === 1) {
      navigate("/about");
    } else if (currentSlide === 2) {
      navigate("/aplicacionMovil");
    } else if (currentSlide === 3) {
      navigate("/DetailsProduct/CREDITO/8");
    } else if (currentSlide === 4) {
      navigate("/DetailsProduct/AHORRO/11");
    } else if (currentSlide === 5) {
      navigate("/cajeros");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <ul style={{ margin: "0" }}>{dots}</ul>,
    customPaging: () => <div className="slick-dot"></div>,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const [animationStarted, setAnimationStarted] = useState(false);
  const [pulsateCount, setPulsateCount] = useState(0);
  useEffect(() => {
    setAnimationStarted(true);
  }, []);

  const handleEntered = () => {
    setPulsateCount((prevCount) => (prevCount + 1) % 3);
  };

  //Array con los componentes para redireccionar
  const slides = [
    // Diapositiva de actualiza tu expediente
    {
      content: (
        <div
          key={0}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(0);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "59%", top: "20%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 0}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={presionaAqui}
                  alt={presionaAqui}
                  style={{
                    maxWidth: "240%",
                    height: "72%",
                  }}
                />
              </Grow>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "59%", top: "20%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 0}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={puntero}
                  alt={puntero}
                  style={{
                    maxWidth: "240%",
                    height: "72%",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none",
                  }}
                />
              </Grow>
            </Grid>
            <Grid item xs={4}>
              <Slide
                direction="up"
                in={currentSlide === 0}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 1000, exit: 1000 }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "-18%",
                    marginTop: "-20%",
                  }}
                >
                  <img
                    src={meses}
                    alt={meses}
                    style={{
                      width: "253%",
                      position: "absolute",
                      left: "36%",
                    }}
                  />
                </div>
              </Slide>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "51%", top: "12%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 0}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={estimadoSocio}
                  alt={estimadoSocio}
                  style={{
                    maxWidth: "220%",
                    height: "19%",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none",
                  }}
                />
              </Grow>
            </Grid>
          </Grid>
          <style>
            {`
          @keyframes pulsate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }
        `}
          </style>
        </div>
      ),
    },

    /*1era diapositiva*/
    {
      content: (
        <div
          key={1}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(1);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", left: "48%", top: "539%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 1}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={Logoveinticinco}
                  alt={Logoveinticinco}
                  style={{
                    maxWidth: "56%",
                    height: "22%",
                  }}
                />
              </Grow>
            </Grid>
            <Grid item xs={4}>
              <Slide
                direction="up"
                in={currentSlide === 1}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 1000, exit: 1000 }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "-18%",
                    marginTop: "-20%",
                  }}
                >
                  <img
                    src={aniversario}
                    alt={aniversario}
                    style={{
                      width: "253%",
                      position: "absolute",
                      left: "36%",
                    }}
                  />
                </div>
              </Slide>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "51%", top: "12%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 1}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={anios}
                  alt={anios}
                  style={{
                    maxWidth: "220%",
                    height: "19%",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none",
                  }}
                />
              </Grow>
            </Grid>
          </Grid>
          <style>
            {`
          @keyframes pulsate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }
        `}
          </style>
        </div>
      ),
    },

    /*2da diapositiva*/
    {
      content: (
        <div
          key={2}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(2);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", left: "10%", top: "-5%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={Descarga}
                  alt={Descarga}
                  style={{
                    maxWidth: "76vw",
                    height: "46vw",
                  }}
                />
              </Grow>
            </Grid>
            <Grid item xs={4}>
              <Slide
                direction="up"
                in={currentSlide === 2}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 1000, exit: 1000 }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "-21%",
                    marginTop: "-18%",
                  }}
                >
                  <img
                    src={CandadinCelLetras}
                    alt={CandadinCelLetras}
                    style={{
                      width: "260%",
                      position: "absolute",
                      left: "36%",
                    }}
                  />
                </div>
              </Slide>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "57%", top: "-150%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={NuestraApp}
                  alt={NuestraApp}
                  style={{
                    maxWidth: "78vw",
                    height: "68vw",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none",
                  }}
                />
              </Grow>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "44%", top: "160%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={CslhMovil}
                  alt={CslhMovil}
                  style={{
                    maxWidth: "155vw",
                    height: "29vw",
                  }}
                />
              </Grow>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "53%", top: "160%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={CslhAmovil}
                  alt={CslhAmovil}
                  style={{
                    maxWidth: "155vw",
                    height: "29vw",
                  }}
                />
              </Grow>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "32%", top: "451%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={GooglePlay}
                  alt={GooglePlay}
                  style={{
                    maxWidth: "21vw",
                    height: "11vw",
                  }}
                />
              </Grow>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "59%", top: "451%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 2}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={AppStore}
                  alt={AppStore}
                  style={{
                    maxWidth: "21vw",
                    height: "11vw",
                  }}
                />
              </Grow>
            </Grid>
          </Grid>
          <style>
            {`
          @keyframes pulsate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }
        `}
          </style>
        </div>
      ),
    },

    /*3er diapositiva*/
    {
      content: (
        <div
          key={3}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(3);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", left: "12%", top: "62%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 3}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={Moto}
                  alt={Moto}
                  style={{
                    maxWidth: "87vw",
                    height: "47vw",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none", // Aplica la animación solo en las primeras dos pulsaciones
                  }}
                />
              </Grow>
            </Grid>
            <Grid item xs={4}>
              <Slide
                direction="up"
                in={currentSlide === 3}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 1000, exit: 1000 }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "-17%",
                    marginTop: "-24%",
                  }}
                >
                  <img
                    src={Vehiculos}
                    alt={Vehiculos}
                    style={{
                      width: "286%",
                      position: "absolute",
                      left: "31%",
                    }}
                  />
                </div>
              </Slide>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "76%", top: "220%" }}
            >
              <Grow
                in={animationStarted && currentSlide === 3}
                timeout={{ enter: 2000, exit: 2000 }}
                onEntered={handleEntered}
              >
                <img
                  src={Iva}
                  alt={Iva}
                  style={{
                    maxWidth: "23vw",
                    height: "23vw",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none", // Aplica la animación solo en las primeras dos pulsaciones
                  }}
                />
              </Grow>
            </Grid>
          </Grid>
          <style>
            {`
          @keyframes pulsate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }
        `}
          </style>
        </div>
      ),
    },

    /*4ta diapositiva*/
    {
      content: (
        <div
          key={4}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(4);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grow
                in={currentSlide === 4}
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={Letras}
                  alt={Letras}
                  style={{
                    width: "89vw",
                    position: "relative",
                    left: "100%",
                    marginTop: "-21%",
                  }}
                />
              </Grow>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", left: "56%", top: "-3%" }}
            >
              <Slide
                direction="right"
                in={currentSlide === 4}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={Actor}
                  alt={Actor}
                  style={{ maxWidth: "44vw", height: "44vw" }}
                />
              </Slide>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "41%", top: "10%" }}
            >
              <Slide
                direction="up"
                in={animationStarted && currentSlide === 4}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={TarjetaDebito}
                  alt={TarjetaDebito}
                  style={{
                    maxWidth: "70vw",
                    animation:
                      pulsateCount < 2 ? "pulsate 1s infinite" : "none",
                  }}
                />
              </Slide>
            </Grid>
          </Grid>
        </div>
      ),
    },

    /*5ta diapositiva*/
    {
      content: (
        <div
          key={5}
          className="carousel-slide"
          style={{ width: "100%" }}
          onClick={() => {
            setCurrentSlide(5);
            handleCarouselClick();
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", right: "64%", top: "-2%" }}
            >
              <Slide
                direction="right"
                in={currentSlide === 5}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={Texto}
                  alt={Texto}
                  style={{ maxWidth: "97vw", height: "47vw" }}
                />
              </Slide>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grow
                in={currentSlide === 5}
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={Cajeros}
                  alt={Cajeros}
                  style={{
                    width: "88%",
                    position: "relative",
                    left: "208%",
                    top: "-12%",
                  }}
                />
              </Grow>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ position: "absolute", left: "45%", top: "67%" }}
            >
              <Slide
                direction="up"
                in={currentSlide === 5}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 2000, exit: 2000 }}
              >
                <img
                  src={Red}
                  alt={Red}
                  style={{ maxWidth: "21vw", height: "21vw" }}
                />
              </Slide>
            </Grid>
          </Grid>
        </div>
      ),
    },
  ];

  return (
    <div className="carousel-container">
      <Slider {...settings}>{slides.map((slide) => slide.content)}</Slider>
      <div className="carousel-buttons">
        <NextArrow />
        <PrevArrow />
      </div>
    </div>
  );
};

export default MyCarousel;
