import React from "react";
import {
  Container,
  Typography,
  Box,
  keyframes,
  CardMedia,
  Grid,
  Fade,
  Card,
  Dialog,
} from "@mui/material";
import imgRibbon from "../img/Empleos/RibbonEmpleos.webp";
import ButtonArrow from "./ButtonArrow";
import GroupButton from "./GroupButtons";

// Imagenes de vacantes
import ImgMarketign from "../img/Empleos/Flayer/Marketing.webp";
import ImgOficial from "../img/Empleos/Flayer/OficialCaptacion.webp";
import ImgRH from "../img/Empleos/Flayer/RecursosHumanos.webp";
import ImgAgro from "../img/Empleos/Flayer/AgroNegocios.webp";

//Para hacer que el titulo principal tenga animacion de pulsacion
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Arreglo de imágenesVentasCatalogos
const imagesServiciosVentasCatalogo = [
  {
    id: 1,
    image: ImgMarketign,
    type: "Promociones",
    name: "Jefe de Mercadotecnia",
    status: "Activo",
  },
  {
    id: 2,
    image: ImgOficial,
    type: "Promociones",
    name: "Oficial de Captación",
    status: "Activo",
  },
  {
    id: 3,
    image: ImgRH,
    type: "Promociones",
    name: "Jefe de Recursos Humanos",
    status: "Activo",
  },
  
  {
    id: 3,
    image: ImgAgro,
    type: "Promociones",
    name: "Jefe de AgroNegocios",
    status: "Activo",
  },
];

export default function Empleos() {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleCardClick = (image) => {
    setSelectedImage(image.image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Separar las promociones por estado
  const activePromotions = imagesServiciosVentasCatalogo.filter(
    (item) => item.status === "Activo"
  );
  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton />

      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm",
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />

        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "2.7rem", sm: "3.3rem", md: "4.5rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          BOLSA DE TRABAJO
        </Typography>

        {/* Diálogo para imágenes */}
        <Dialog open={open} onClose={handleClose} onClick={handleClose}>
          <img src={selectedImage} alt="" loading="lazy" />
        </Dialog>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1%",
          mx: "2%",
        }}
      >
        <Grid container spacing={3} maxWidth="xl" justifyContent="center">
          {activePromotions.length > 0 ? (
            activePromotions.map((image) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                key={image.id}
                sx={{ mb: 5, marginTop: "12px" }}
              >
                <Fade in timeout={3000}>
                  <Card
                    sx={{
                      position: "relative",
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() => handleCardClick(image)}
                  >
                    <CardMedia
                      component="img"
                      height="325"
                      image={image.image}
                      alt={image.type}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: "#0C213D",
                        opacity: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "opacity 0.3s",
                        "&:hover": {
                          opacity: 0.9,
                        },
                      }}
                    >
                      <Box>
                        <Typography variant="h7" color="white">
                          {image.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Fade>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" align="center" color="#0C213D" mt={"4%"}>
              Sin promociones activas
            </Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
}
