import React from "react";
import {
  Container,
  Typography,
  Box,
  keyframes,
  Grid,
  CardMedia,
} from "@mui/material";
import imgRibbon from "../../components/img/BuroEntidades/ribbonBuroEntidades.webp";
import ButtonArrow from "../pages/ButtonArrow";
import GroupButtons from "../pages/GroupButtons";
import ImgCartel from "../img/BuroEntidades/cartel_2023.webp";

//Para hacer las pulsaciones del titulo principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export default function BuroEntidades() {

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "6rem" }}>
      <ButtonArrow />
      <GroupButtons />
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
          loading="lazy"
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "3.4rem", sm: "4.3rem", md: "4.3rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          Buró de Entidades Financieras
        </Typography>
      </Box>

      <Container maxWidth="xl" sx={{ marginBottom: "6rem" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image={ImgCartel}
              title="Imagen descriptiva del Buró de entidades financieras"
              style={{ maxWidth: "70%", height: "auto", borderRadius: "9px" }} // Aplica un borde redondeado a la imagen
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              align="center"
              color={"#0c213d"}
              gutterBottom
            >
              ¿Qué es el Buró de Entidades Financieras?
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Es una herramienta de consulta y difusión con la que podrás
              conocer los productos que ofrecen las entidades financieras, sus
              comisiones y tasas, las reclamaciones de los usuarios, las
              prácticas no sanas en que incurren, las sanciones administrativas
              que les han impuesto, las cláusulas abusivas de sus contratos y
              otra información que resulte relevante para informarte sobre su
              desempeño.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Con el Buró de Entidades Financieras, se logrará saber quién es
              quién en bancos, seguros, sociedades financieras de objeto
              múltiple, cajas de ahorro, afores, entre otras entidades.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Con ello, podrás comparar y evaluar a las entidades financieras,
              sus productos y servicios y tendrás mayores elementos para elegir
              lo que más te convenga.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Esta información te será útil para elegir un producto financiero y
              también para conocer y usar mejor los que ya tienes.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Este Buró de Entidades Financieras, es una herramienta que puede
              contribuir al crecimiento económico del país, al promover la
              competencia entre las instituciones financieras; que impulsará la
              transparencia al revelar información a los usuarios sobre el
              desempeño de éstas y los productos que ofrecen y que va a
              facilitar un manejo responsable de los productos y servicios
              financieros al conocer a detalle sus características.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Lo anterior, podrá derivar en un mayor bienestar social, porque al
              conjuntar en un solo espacio tan diversa información del sistema
              financiero, el usuario tendrá más elementos para optimizar su
              presupuesto, para mejorar sus finanzas personales, para utilizar
              correctamente los créditos que fortalecerán su economía y obtener
              los seguros que la protejan, entre otros aspectos.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              sx={{ textAlign: "justify" }}
            >
              Si es de su interés conocerla informacion de todo el sector,
              puedes acceder al sitio de internet oficial de Buro de Entidades
              Financieras{" "}
              <a href="https://www.buro.gob.mx.">https://www.buro.gob.mx.</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
