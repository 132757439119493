import React from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Grid,
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
  List,
  ListItem,
  SvgIcon,
  ListItemIcon,
  Tab,
  IconButton,
  keyframes,
} from "@mui/material";
import imgRibbon from "../../img/Services/Seguros/ribbonSeguros.webp";
import ButtonArrow from "../ButtonArrow";
import styled from "@emotion/styled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExpandFuntionIcon from "@mui/icons-material/AddCircle";
import RetractFunctionIcon from "@mui/icons-material/RemoveCircle";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ImgSeguro from "../../img/Services/Seguros/vehiculoSeguro.webp";
import imgSeguroMoto from "../../img/Services/Seguros/vehiculoSeguroMoto.webp";
import ButtonGroup from "../GroupButtons";

//Para hacer las pulsaciones del titulo principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Img = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "750px",
  height: "auto",
  borderRadius: "14px",
}));

// Icons change color start
const OrangeArrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <ArrowRightIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);

const NavyExpandicon = (props) => (
  <SvgIcon {...props}>
    <ExpandFuntionIcon sx={{ color: "#0C213D" }} />
  </SvgIcon>
);

const OrangeRetractIcon = (props) => (
  <SvgIcon {...props}>
    <RetractFunctionIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);
//Iconos change color end

const data = [
  {
    id: 1,
    name: "Requisitos:",
    details: "",
  },
];

const data2 = [
  {
    id: 4,
    name: "Considerar:",
    details: "",
  },
];

function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function Seguros() {
  const [value, setValue] = React.useState(0);
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = (id) => {
    const currentIndex = expandedRows.indexOf(id);
    const newExpandedRows = [...expandedRows];

    if (currentIndex === -1) {
      newExpandedRows.push(id);
    } else {
      newExpandedRows.splice(currentIndex, 1);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: "6rem" }}>
      <ButtonArrow />
      <ButtonGroup />
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "4.7rem", sm: "6.2rem", md: "6.2rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          SEGUROS
        </Typography>
      </Box>    
      {/*introduction Start*/}
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" color={"#0c213d"} gutterBottom>
          Seguro automotriz
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "justify", mx: "3rem" }}
        >
          Los seguros automotrices representan un compromiso de resguardo
          financiero que nos brinda la seguridad de saber que, en caso de
          imprevistos en la carretera, estamos respaldados. En este contexto,{" "}
          <strong>Caja Solidaria La Huerta</strong> se enorgullece de ser tu
          aliado en la búsqueda de la seguridad y la estabilidad en la
          conducción. Comprendemos que los desafíos en el camino son parte de la
          vida, y por ello, ofrecemos una variedad de servicios de seguros
          automotrices diseñados para salvaguardar tus vehículos y ofrecerte
          tranquilidad en cada viaje.
        </Typography>
      </Box>
      {/*introduction End*/}

      {/*Socios tabs start*/}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tab partners"
            centered
          >
            <Tab
              icon={<ContentPasteIcon />}
              label="Requisitos"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
            <Tab
              icon={<HowToRegIcon />}
              label="Consideraciones"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
          </Tabs>
        </Box>

        {/*Contend tabs start*/}
        <TabPanel value={value} index={0}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#0C213D"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            Documentación necesaria
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ textAlign: "justify", mx: "2rem" }}
          >
            En la búsqueda de la seguridad financiera y la protección de
            nuestros bienes, los seguros se convierten en un recurso invaluable.
            Sin embargo, antes de poder adquirir un seguro, es fundamental
            comprender y cumplir con los requisitos necesarios.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <IconButton onClick={() => handleExpandClick(row.id)}>
                            {expandedRows.includes(row.id) ? (
                              <OrangeRetractIcon />
                            ) : (
                              <NavyExpandicon />
                            )}
                          </IconButton>
                          {row.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 1 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  INE.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Tarjeta de circulación.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Copia de factura del auto.
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={6}>
              <Img src={ImgSeguro} alt="Socio caja solidaria la huerta" />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#FB6823"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            ¡Importante!
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ textAlign: "justify", mx: "2rem" }}
          >
            Cuando se trata de adquirir un seguro de automóviles, es esencial
            prestar una atención meticulosa a una serie de consideraciones que
            influirán significativamente en la elección y en la satisfacción con
            el seguro seleccionado. En este apartado, exploraremos algunas de
            las consideraciones cruciales que deben tenerse en cuenta al comprar
            seguros para vehículos.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data2.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <IconButton onClick={() => handleExpandClick(row.id)}>
                            {expandedRows.includes(row.id) ? (
                              <OrangeRetractIcon />
                            ) : (
                              <NavyExpandicon />
                            )}
                          </IconButton>
                          {row.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 4 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Cotización sin compromiso.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Si eres socio recibes 20% de descuento.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  El pago se hace en ventanilla después de
                                  liberar la póliza y es en una sola exhibición
                                  en efectivo .
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={6}>
              <Img src={imgSeguroMoto} alt="Motocicleta" />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}
