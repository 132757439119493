import React from "react";
// Botón para subir la pantalla
import ButtonArrow from "../ButtonArrow";
import GroupButton from "../GroupButtons.jsx";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Dialog,
  Fade,
  Grid,
  keyframes,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Imágenes para el inicio
import actualiza from "../../img/Services/FileUpdate/actualiza.webp";
import tuExpediente from "../../img/Services/FileUpdate/tuExpediente.webp";
import ImgFunerales from "../../img/Services/FileUpdate/FlayerFunerales.webp";

// Arreglo de imágenesVentasCatalogos
const imagesServiciosVentasCatalogo = [
  {
    id: 1,
    image: ImgFunerales,
    type: "Beneficio",
    name: "Beneficio",
    status: "Activo",
  },
];

// Animación de palpitación
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Componente estilizado para la imagen con animación
const AnimatedImage = styled("img")(({ animationDuration }) => ({
  width: "80%",
  maxWidth: "500px",
  objectFit: "contain",
  animation: `${pulse} ${animationDuration} infinite`,
}));

const FileUpdate = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleCardClick = (image) => {
    setSelectedImage(image.image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Separar las promociones por estado
  const activePromotions = imagesServiciosVentasCatalogo.filter(
    (item) => item.status === "Activo"
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginBottom: { xs: "20%", sm: "15%", md: "5%", lg: "5%" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ButtonArrow />
      <GroupButton />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        {/* Primera imagen */}
        <img
          src={actualiza}
          alt="actualiza"
          style={{
            width: "300%",
            maxWidth: "65%",
            objectFit: "contain",
          }}
        />

        <AnimatedImage
          src={tuExpediente}
          alt="NuestraApp"
          animationDuration="2s"
          style={{
            marginTop: "-10%",
          }}
        />
      </div>

      <Typography sx={{ mx: "1rem", marginTop: "-7%" }} variant="h6">
        Recuerda que esnecesario actualizar la información de tu expediente
        durante <strong>los primeros 3 meses del año</strong>.
      </Typography>
      <Typography variant="h6" sx={{ mt: 3 }}>
        Requisitos para la actualización:
      </Typography>
      <List>
        {[
          "Identificación oficial vigente (INE o pasaporte)",
          "Comprobante de domicilio reciente (no mayor a 3 meses)",
          "Comprobante de ingresos",
        ].map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleIcon color="success" />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Y no te pierdas el beneficio de obtener tu paquete funerario sin costo,
        el cual incluye:
      </Typography>
      {/* Diálogo para imágenes */}
      <Dialog open={open} onClose={handleClose} onClick={handleClose}>
        <img src={selectedImage} alt="" loading="lazy" />
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1%",
          mx: "2%",
        }}
      >
        <Grid container spacing={3} maxWidth="xl" justifyContent="center">
          {activePromotions.length > 0 ? (
            activePromotions.map((image) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                key={image.id}
                sx={{ mb: 5, marginTop: "12px" }}
              >
                <Fade in timeout={3000}>
                  <Card
                    sx={{
                      position: "relative",
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() => handleCardClick(image)}
                  >
                    <CardMedia
                      component="img"
                      height="325"
                      image={image.image}
                      alt={image.type}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: "#0C213D",
                        opacity: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "opacity 0.3s",
                        "&:hover": {
                          opacity: 0.9,
                        },
                      }}
                    >
                      <Box>
                        <Typography variant="h7" color="white">
                          {image.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Fade>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" align="center" color="#0C213D" mt={"4%"}>
              Sin beneficios activos
            </Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default FileUpdate;
