import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import CalculateIcon from "@mui/icons-material/Calculate";
import PaymentsSharpIcon from "@mui/icons-material/PaymentsSharp";
import BankIcon from "@mui/icons-material/AccountBalance";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Hidden } from "@mui/material";
import styled from "@emotion/styled";

const FloatingButtons = () => {
  const navigate = useNavigate();

  const handleClick = (path, hash) => {
    navigate(`${path}${hash ? `#${hash}` : ""}`);
  };

  //Funcion encargada de hacer que el telefono se vea como si estuviera timbrando
  const RingAnimation = styled("div")`
    animation: ring 2s ease-in-out infinite;

    @keyframes ring {
      0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(30deg);
      }
      20% {
        transform: rotate(-20deg);
      }
      30% {
        transform: rotate(20deg);
      }
      40% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(10deg);
      }
      60% {
        transform: rotate(-5deg);
      }
      70% {
        transform: rotate(5deg);
      }
      80% {
        transform: rotate(-2deg);
      }
      90% {
        transform: rotate(2deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  `;
  //Fin de la funcion encargada de hacer que el telefono se vea como si estuviera timbrando

//Inicio de la funcion encargada de dar vueltas 
  const SpinAnimation = styled("div")`
    animation: spin 4s linear infinite;

    @keyframes spin {
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(1turn);
      }
      75%,
      100% {
        transform: rotateY(1turn);
      }
    }
  `;
//Fin de la funcion encargada de dar vueltas 

//Inicio de la funcion  pulsaciones
const PulseAnimation = styled("div")`
  animation: pulse 3s ease-in-out infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
//Fin de la funcion  pulsaciones

//Inicio de la funcion vibraciones
const VibrateAnimation = styled("div")`
animation: fade 3s ease-in-out infinite;

  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;
//Fin de la funcion  vibraciones
  return (
    <Hidden lgDown>
      <Box sx={{ position: "fixed", left: 5, top: "45%", zIndex: 9999 }}>
        <Tooltip title="Pago de Remesas" placement="right">
          <Fab
            color="primary"
            aria-label="Pago de Remesas"
            onClick={() => handleClick("/remesas")}
            sx={{
              marginBottom: "7px",
              background: "#FB6823",
              "&:hover": { background: "#0C213D" },
            }}
          >
            <SpinAnimation>
              <PaymentsSharpIcon />
            </SpinAnimation>
          </Fab>
        </Tooltip>
        <br />
        <Tooltip title="Simulador de crédito " placement="right">
          <Fab
            color="primary"
            aria-label="Simuladores"
            onClick={() => handleClick("/cotizadorCreditos")}
            sx={{
              marginBottom: "7px",
              background: "#FB6823",
              "&:hover": { background: "#0C213D" },
            }}
          >
            <VibrateAnimation>
            <CalculateIcon />
            </VibrateAnimation>
          </Fab>
        </Tooltip>
        <br />
        <Tooltip title="Cajeros" placement="right">
          <Fab
            color="primary"
            aria-label="Cajeros"
            onClick={() => handleClick("/cajeros")}
            sx={{
              marginBottom: "7px",
              background: "#FB6823",
              "&:hover": { background: "#0C213D" },
            }}
          >
            <PulseAnimation>
            <BankIcon />
            </PulseAnimation>
          </Fab>
        </Tooltip>
        <br />
        <Tooltip title="Inversiones" placement="right">
          <Fab
            color="primary"
            aria-label="Inversiones"
            onClick={() => handleClick("/detailsProduct/INVERSION/15")}
            sx={{
              marginBottom: "7px",
              background: "#FB6823",
              "&:hover": { background: "#0C213D" },
            }}
          >
            <RingAnimation>
              <AutoGraphIcon />
            </RingAnimation>
          </Fab>
        </Tooltip>
      </Box>
    </Hidden>
  );
};

export default FloatingButtons;
